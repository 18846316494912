import { useContext, useEffect } from "react";
import { PopupsContext } from "../context/popups";
import "../styles/button.css";
function FifthModal(props) {
  const { popshow, onChange } = useContext(PopupsContext)
  // useEffect(() => {
  //   console.log(popshow);
  // }, [popshow])
  return (
    <div>
      {!!popshow ? <div className="image_modal" style={{
        overflow: 'hidden',
      }}>
        <div className="modal_back"
          onClick={() => onChange(null)}></div>
        <div
          className="modal_swiper_box"
          style={{
            opacity: !!popshow ? 1 : 0,
            transition: '.5s'
          }}>
          <div className="modal_back"
            onClick={() => onChange(null)}></div>
          <div
            className="modal_swiper"
            loop={popshow.length > 1}
            spaceBetween={0}
            style={{
              borderRadius: '10px',
              border: '4px solid #fff',
              maxHeight: 700
            }}
            navigation
          >
            <div className="image_box">
              <video controls
                autoPlay
                src={popshow[0]}></video>
            </div>
          </div>
        </div>
      </div> : null}
    </div>
  );
}

export default FifthModal;
