import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../context/language";
import Button from "./Button";
import Image from "./Image";
import Search from "./Search";
import '../styles/header.css'
import { SkrollrContext } from "../context/skrollrProvider";
import locals from "../utils/locals";

function Header({
  isHome,
  changeShow,
  content = '',
  setContent = () => null,
  onSearch = () => null
}) {
  const { s, hash, loading } = useContext(SkrollrContext)
  const { local, change } = useContext(LanguageContext)
  const [open, setOpen] = useState(false)
  const [scrollY, setScrollY] = useState(0)
  useEffect(() => {
    if (window.innerWidth >= 812) return
    window.addEventListener('scroll', scrollYchange)
    return () => window.removeEventListener('scroll', scrollYchange)
  }, [])
  const scrollYchange = () => {
    setScrollY(window.scrollY)
  }
  useEffect(() => {
    var link = document.querySelector('a');
    if (!loading && window.innerWidth >= 812) window.skrollr.menu.click(link);
  }, [])
  const phoneMenu = () => {
    return <div className="header_flex_box">
      <div style={{ width: window.innerWidth < 812 ? '6vw' : '40px', cursor: 'pointer' }}
        onClick={() => setOpen(!open)}>
        <img style={{ width: '100%', height: '100%' }} src="/images/menu.png"></img>
      </div>
      {open ? <div className="header_phone_menu">
        {/* logo 返回 */}
        <div style={{
          backgroundColor: '#000000',
          width: 'calc(100% - 10vw)',
          padding: '0 5vw',
          height: '68px',
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
        }}>
          <div className="header_logo"
            onClick={() => window.location.href = '/'}>
            <img src={'/images/logo.png'} alt=''></img>
          </div>
          <div style={{ flex: 1 }} />
          <div
            style={{ width: '7vw' }}
            onClick={() => {
              setOpen(!open)
            }}><img style={{ width: '100%' }}
              src="/images/x.svg" alt=""></img></div>
        </div>
        {/* 按钮组 */}
        <div className='header_phone_buttons'>
          <Button active={
            window.pageYOffset >= window.innerHeight &&
            window.pageYOffset < window.innerHeight * 2
          } onClick={() => { setOpen(false) }}>
            <a href="#second-page">{locals[local].header.title1}</a>
          </Button>
          <Button active={
            window.pageYOffset >= window.innerHeight * 2 &&
            window.pageYOffset < window.innerHeight * 3.5
          } onClick={() => { setOpen(false) }}>
            <a href="#fourth-page">{locals[local].header.title2}</a>
          </Button>
          <Button active={
            window.pageYOffset >= window.innerHeight * 3.5 &&
            window.pageYOffset < window.innerHeight * 5
          } onClick={() => { setOpen(false) }}>
            <a href="#fifth-page">{locals[local].header.title3}</a>
          </Button>
        </div>
        <div className="header_phone_icons">
          <Button style={{ margin: 8 }} icon active={local === 'zh'} onClick={() => change('zh')}>{locals[local].header.zh}</Button>
          <Button style={{ margin: 8 }} icon active={local === 'en'} onClick={() => change('en')}>{locals[local].header.en}</Button>
        </div>
      </div> : null
      }
    </div >
  }
  const windowMenu = () => {
    return <div className="header_flex_box">
      <Button active={hash === '#second-page'} style={{ marginRight: '1.5vw' }}>
        <a href="#second-page" data-menu-top={1000}>{locals[local].header.title1}</a>
      </Button>
      <Button active={hash === '#fourth-page'} style={{ marginRight: '1.5vw' }}>
        <a href="#fourth-page" data-menu-top={3000}>{locals[local].header.title2}</a>
      </Button>
      <Button active={hash === '#fifth-page'} style={{ marginRight: '1.5vw' }}>
        <a href="#fifth-page" data-menu-top={4500}>{locals[local].header.title3}</a>
      </Button>
      <Button icon active={local === 'zh'} onClick={() => change('zh')}>{locals[local].header.zh}</Button>
      <Button icon active={local === 'en'} onClick={() => change('en')}>{locals[local].header.en}</Button>
    </div>
  }
  return (
    <div className="header">
      <div className="header_logo" onClick={() => window.location.href = '/'}>
        {<img src={
          (window.innerWidth < 812 ? (scrollY >= (window.innerHeight + 20)) : (s && s.getScrollTop() >= 1800)) &&
            (window.innerWidth < 812 ? (scrollY <= (window.innerHeight * 3.5 - 60)) : (s && s.getScrollTop() <= 2892)) ?
            '/images/logo2.png' : '/images/logo.png'} alt=''></img>}
      </div>
      <div style={{ flex: 1 }}></div>
      {
        isHome ? (
          window.innerWidth < 812 ? phoneMenu() : windowMenu()
        ) : <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <Search value={content} onChange={setContent} onSearch={onSearch} />
          <div
            style={{
              marginLeft: '2vw',
              cursor: 'pointer',
              maxWidth: '7vw'
            }}
            onClick={() => {
              window.innerWidth >= 812 ? (changeShow ? changeShow(false) : window.history.back()) :
                window.history.back()
            }}><img style={{ width: '80%' }}
              src="/images/close.png" alt=""></img></div>
        </div>
      }
    </div >
  );
}

export default Header;
