

/**
 * 语言切换
 */
import React, { useState, useEffect, createContext } from 'react'
export const LanguageContext = createContext({
  local: 'zh',
  change: () => null
})

export default function LanguageProvider({ children }) {
  const [local, setLocal] = useState('zh')
  useEffect(() => {
    if (!navigator) return
    if (navigator.language === 'zh' || navigator.language === 'en') {
      setLocal(navigator.language)
    } else {
      setLocal('zh')
    }
  }, [])
  const onChange = (e) => {
    if (e) {
      setLocal(e)
    } else {
      setLocal(local === 'zh' ? 'en' : 'zh')
    }
  }
  const contextValue = {
    local,
    change: onChange
  }

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  )
}
