import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Header from "../components/Header";
import Image from "../components/Image";
import '../styles/works.css'
import api from "../utils/api";

function WorksScreen(props) {
  const { show = true } = props
  const [content, setContent] = useState('')
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  useEffect(() => {
    if (show) getData()
  }, [show, id, content])
  const getData = () => {
    const cid = id || 1
    fetch(`${api}/cases/case/?category=${id}&search=${content}`)
      .then((e) => e.json())
      .then((res) => {
        const k = res.sort((a, b) => {
          return (new Date(b.times)).getTime() - (new Date(a.times)).getTime()
        })
        setList(res)
      })
  }
  return (
    <div>
      {show ? <div style={{ position: 'relative', zIndex: 2002 }}>
        <Header
          content={content}
          setContent={(e) => {
            setContent(e)
          }}
          onSearch={() => {
            getData()
          }} />
      </div> : null}
      {show ? <div className="works">
        <div className="first_head"></div>
        <div className="works_list_box">
          <div className="list_box">
            {list.map((item, index) => {
              const describe = item.describe.replace('\r\n', '<br/>')
              return <div className="list_item"
                key={index}>
                <Image
                  title={item.title}
                  describe={describe}
                  time={item.times ? item.times : item.created_at}
                  preview={item.preview}
                  address={item.addres}
                  swiperStyle={id == 2 ? {} : null}
                  src={item.list} style={{
                    margin: 10
                  }}
                  imgStyle={{
                    transform: 'translateX(0%)'
                  }}></Image>
              </div>
            })}
          </div>
        </div>
      </div> : null}
    </div>
  );
}

export default WorksScreen;
