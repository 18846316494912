import { useContext, useEffect, useState } from "react";
import { SkrollrContext } from "../context/skrollrProvider";
import "../styles/button.css";
function Cartoon(props) {
  const [show, setShow] = useState(false)
  const { scrollLength } = useContext(SkrollrContext)
  return (
    <div
      className="cartoon_box"
      {...props}>
      <div className="cartoon_person" style={{
        width: '91.6%',
        height: '100%',
        position:'absolute',
        zIndex:3000,
        backgroundImage: 'url(/images/person2.png)',
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: scrollLength * 5.2631 + '%',
      }}></div>
    </div>
  );
}

export default Cartoon;
