import React, { useState, createContext } from 'react'
export const PopupsContext = createContext({
  onChange: () => null,
  popshow: null
})

export default function PopupsProvider({ children }) {
  const [popshow, setShow] = useState(null)
  const onChange = (e) => {
    setShow(e)
  }
  const contextValue = {
    onChange,
    popshow
  }

  return (
    <PopupsContext.Provider value={contextValue}>
      {children}
    </PopupsContext.Provider>
  )
}
