import { useEffect, useState } from "react";
import "../styles/button.css";
import api from "../utils/api";
function Contact(props) {
  const [show, setShow] = useState(false)
  const [content, setContent] = useState(null)
  const [scrollY, setScrollY] = useState(0)
  useEffect(() => {
    getContact()
  }, [])
  const getContact = () => {
    fetch(api + '/contacts/contact/')
      .then((e) => e.json())
      .then((res) => {
        setContent(res[0])
      })
  }
  useEffect(() => {
    if (window.innerWidth >= 812) return
    window.addEventListener('scroll', scrollYchange)
    return () => window.removeEventListener('scroll', scrollYchange)
  }, [])
  const scrollYchange = () => {
    setScrollY(window.scrollY)
  }
  return (
    <div
      className="contact_box"
      style={{
        height: window.innerWidth < 812 ? (show ? '100vh' : '24.5vw') : 'auto',
        width: window.innerWidth < 812 ? (show ? '100vw' : '24.5vw') : 'auto',
        bottom: window.innerWidth < 812 ? (scrollY > window.innerHeight ? '2vw' : '-28vw') : '2vw',
        transition: window.innerWidth < 812 ? '.6s' : 0
      }}
      onClick={() => show && setShow(!show)}
      onMouseLeave={() => setShow(false)}
      {...props}>
      <div className="contact_list">
        <div
          className="contact_item" style={{
            transform: !show ? 'scale(0)' : 'scale(1)',
            bottom: window.innerWidth < 812 ?
              (!show ? '-8vw' : '13vw') :
              (!show ? '-4vw' : '0vw'),
            left: !show ? '-2vw' : 0,
            marginBottom: '-1.5vw',
            zIndex: 8
          }}><div className="contact_item_text"><div>{content && content.email}</div></div></div>
        <div
          className="contact_item"
          style={{
            transform: !show ? 'scale(0)' : 'scale(1)',
            bottom: window.innerWidth < 812 ?
              (!show ? '-4vw' : '4vw') :
              (!show ? '-4vw' : '-1vw'),
            left: !show ? '-2vw' : '8vw',
            marginBottom: '-1.5vw',
            zIndex: 7
          }}><div className="contact_item_text"><div>{content && content.phone}</div></div></div>
        <div
          className="contact_item"
          style={{
            transform: !show ? 'scale(0)' : 'scale(1)',
            bottom: window.innerWidth < 812 ?
              (!show ? '-4vw' : '-8vw') :
              (!show ? '-4vw' : '-4vw'),
            left: !show ? '-2vw' : '10vw',
            marginBottom: '-1.5vw',
            zIndex: 6
          }}><div className="contact_item_text"><div>{content && content.address}</div></div></div>
      </div>
      {content ? <img
        onMouseOver={() => setShow(true)}
        onClick={() => setShow(!show)}
        style={{
          cursor: 'pointer',
          position: 'relative',
          height: window.innerWidth < 812 ? '24.5vw' : '8vw',
          width: window.innerWidth < 812 ? '24.5vw' : '8vw',
          zIndex: 10
        }}
        src="/images/contact.gif"></img> : null}
    </div>
  );
}

export default Contact;
