import { useContext, useEffect, useState } from "react";
import { FaceContext } from "../context/face";
import "../styles/button.css";
function FaceModal(props) {
  //const { faceShow } = useContext(FaceContext)
  const [scale, setScale] = useState(1)
  const [screenXY, setScreenXY] = useState([0, 0])
  useEffect(() => {
    window.onmousemove = function (e) {
      setScreenXY([e.clientX, e.clientY])
    }
    window.onmousedown = function () {
      setScale(0.9)
    }
    window.onmouseup = function () {
      setScale(1)
    }
  }, [])
  return (
    <div>
      <div
        style={{
          position: 'fixed',
          left: `calc(${screenXY[0]}px - 3vw)`,
          top: `calc(${screenXY[1]}px - 3vw)`,
          transform: `scale(${scale})`,
          height: '6vw',
          width: '6vw',
          zIndex: 2000,
          userSelect: "none",
          pointerEvents: 'none',
          transition: 'opacity .2s,width 0.2s,height 0.2s,transform 0.2s',
          opacity: props.faceRef.current ? 1 : 0,
        }}>
        <img src="/images/mouse.png"
          style={{
            height: '100%',
            width: '100%',
            cursor: 'pointer'
          }}></img>
      </div>
    </div>
  );
}

export default FaceModal;
