
import { useEffect, useRef } from 'react';
import '../../styles/home_first.css'
import '../../styles/base.css'
import { MenuItem } from '../../components/Crosshair/menuItem';
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import Splitting from 'splitting';

function FirstPage() {
  const welcomeRef = useRef()
  const size = 9.03
  useEffect(() => {
    Splitting({
      target: '.wel__item-title',
      by: "chars",
    });
    if (!welcomeRef.current) return
    new MenuItem(welcomeRef.current)
  }, [])
  return (
    <div className="first_page">
      <div className="first_head"></div>
      {/* 背景图 */}
      {/* <div className="first_back_img">
        <img src="/images/first_back.png"></img>
      </div> */}
      <div className='content_top'>
        <div className='item'>
          {/* welcome 环 飞虫 */}
          <div className="first_content">
            <div className="first_welcome">
              {/* <img src='/images/come.gif'></img> */}
              <nav className="wel">
                <div className="wel__item" ref={welcomeRef}>
                  {/* <img data-splitting className="wel__item-title" src='/images/come.gif'></img> */}
                  <div data-splitting className="wel__item-title">WELCOME</div>
                </div>
              </nav>
            </div>
            <div className="first_ring">
              <img src='/images/ring.gif'></img>
            </div>
            {window.innerWidth >= 812 ? <div className="first_fly">
              <img src='/images/brain.gif'></img>
            </div> : null}
          </div>
        </div>
        <div className='item'></div>
      </div>
      <div className='content_bottom'>
        <div className='item'>
          {/* 左下角文本+人物 */}
          <div className="first_person_box" style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <div className="first_person" style={{
              width: '84.6%',
              backgroundImage: 'url(/images/person2.png)',
              backgroundSize: 'auto 100%',
              backgroundRepeat: 'no-repeat',
              backgroundPositionX: '0%'
            }}></div>
          </div>
        </div>
        {window.innerWidth >= 812 ? <div className='item'>
          {/* 右下角箭头 */}
          <a className="first_arrow"
            href="#second-page"
            data-menu-top={(window.innerWidth <= 812 ? window.innerHeight : 1000)}>
            <img src='/images/arrow.png'></img>
          </a>
        </div> : null}
      </div>
    </div>
  );
}

export default FirstPage;
