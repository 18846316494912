import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import WorksScreen from "../works";
import '../../styles/home_second.css'
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { FaceContext } from "../../context/face";
import { EffectCards } from "swiper";
function SecondPage({ faceRef, changeShow }) {
  const { faceChange } = useContext(FaceContext)
  const [list, setList] = useState([1, 2, 3])
  const [scrollY, setScrollY] = useState(0)
  useEffect(() => {
    if (window.innerWidth >= 812) return
    window.addEventListener('scroll', scrollYchange)
    return () => window.removeEventListener('scroll', scrollYchange)
  }, [])
  const scrollYchange = () => {
    setScrollY(window.scrollY)
  }

  const ListBox = ({ children, item, index }) => {
    if (window.innerWidth <= 812) return <div className="second_list_item">
      {children}
    </div>
    return <div
      className='second_list_item'
      onClick={() => {
        window.location.href = '/works/' + item
      }}
      // style={{ cursor: 'url(/mouse.ico),pointer' }}
      data-1050={`transform:rotate(${(index + 1 - list.length) * 10}deg) translate(-50%, -50%)`}
      data-1600={`transform:rotate(${(index) * 10}deg) translate(-50%, -50%)`}
    >
      <img src={`/images/second_${index + 1}.jpg`}></img>
    </div>
  }
  const renderListItem = (item, index) => {
    return <ListBox key={index} index={index} item={item}></ListBox>
  }
  const Container = ({ children }) => {
    if (window.innerWidth <= 812) return <div
      className="second_container"
      style={{
        width: scrollY > window.innerHeight ? ((scrollY - window.innerHeight) + 170 + 'vw') : '170vw',
        height: scrollY > window.innerHeight ? ((scrollY - window.innerHeight) + 170 + 'vw') : '170vw',
        maxWidth: '280vw',
        maxHeight: '280vw'
      }}
      data-950={`width:170vw;height:170vw`}
      data-1050={`width:280vw;height:280vw`}
    >
      {children}
    </div>
    return <div
      className="second_container"
      style={{ borderRadius: '50%' }}
      data-1000={`margin-left:-${window.innerWidth / 2}px;`}
      data-2000={`margin-left:${window.innerWidth / 2}px;`}
    >
      <div
        style={{
          backgroundColor: '#f9dc5c',
          borderRadius: '50%',
          overflow: 'hidden',
          position: 'relative',
          width: '88vw',
          height: '88vw'
        }}
        data-1000={`width:88vw;height:88vw;`}
        data-1650={`width:88vw;height:88vw;`}
        data-2000={`width:150vw;height:150vw;`}
      >
        {children}
      </div>
    </div>
  }
  return (
    <div className="second_page" id='second-page'>
      <Container>
        <div
          style={{
            height: '100%',
            width: '100%',
          }}
          onMouseOver={() => /*faceChange(true)*/faceRef.current = true}
          onMouseLeave={() => /*faceChange(false)*/faceRef.current = false}>
          <div style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            borderRadius: '50%'
          }}></div>
          <img className="second_list_ack" src="/images/ring2.png"></img>
          <div className="second_list_box">
            {window.innerWidth >= 812 ? list.map(renderListItem) :
              <div
                className="second_list_item">
                <Swiper
                  loop
                  spaceBetween={0}
                  navigation
                  effect={"cards"}
                  grabCursor={true}
                  modules={[EffectCards]}
                >
                  <SlideButton title="prev"></SlideButton>
                  <SlideButton title="next"></SlideButton>
                  {list.map((item, index) => <SwiperSlide
                    key={index} className=''>
                    <img style={{ left: 0 }}
                      onClick={() => window.location.href = '/works/' + (3 - index)}
                      src={`/images/second_phone_${3 - index}.jpg`}></img>
                  </SwiperSlide>)}
                </Swiper>
              </div>}
          </div>
        </div>
      </Container >
    </div >
  );
}

export default SecondPage;


function SlideButton(props) {
  const swiper = useSwiper();
  return (
    <div className={`swiper_button swiper_button_${props.title}`}
      onClick={() => {
        if (props.title === 'prev') {
          swiper.slidePrev()
        } else {
          swiper.slideNext()
        }
      }}
      {...props}>
      <img alt="" src={`/images/arrow3.svg`}></img>
    </div>
  );
}