import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import './App.css';
import HomeScreen from './screens/home';
import DemoScreen from './screens/demo';
import LanguageProvider from "./context/language";
import WorksScreen from "./screens/works";
import SkrollrProvider from "./context/skrollrProvider";
// 1、首页文本，图片展示，png帧动画
// 2、第二页轮播图展示
// 3、第二页点击弹出列表页面（以弹窗形式打开，并增加动画）(setState导致动画失效)
// 4、第三屏宽页面展示
// 5、第四屏
function App() {
  return (<div>
    <LanguageProvider>
      <SkrollrProvider>
        <BrowserRouter>
          <meta name="viewport" id="viewport" content="width=device-width, user-scalable=no," />
          <Routes>
            <Route path="/" element={<HomeScreen />}></Route>
            <Route path="/works/:id" element={<WorksScreen />}></Route>
            {/* <Route path="/demo" element={<DemoScreen />}></Route> */}
          </Routes>
        </BrowserRouter>
      </SkrollrProvider>
    </LanguageProvider>
  </div>);
}

export default App;