import { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import "../styles/image.css";
import api from "../utils/api";
import { PopupsContext } from "../context/popups";
function Image(props) {
  const imgs = ['png', 'jpg', 'jpeg', 'svg', 'gif', 'tiff', 'bmp', 'webp', 'raw']
  const { onChange } = useContext(PopupsContext)
  const { src = [],
    title = '',
    time = '',
    address = '', imgStyle = {}, describe, preview,
    isHome = false,
    style = {},
    swiperStyle = null,
    otherProps,
    motion_video,
  } = props
  const [show, setShow] = useState(false)
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setVisible(show)
      }, 300);
    } else {
      setVisible(show)
    }
  }, [show])
  return (
    <div style={{ height: '100%' }}>
      <div
        onClick={() => {
          isHome ? onChange(src) : setShow(true)
        }}
        className={`image`}
        style={{ ...style }}
        {...otherProps}>
        {preview ? <div style={{
          height: 0,
          paddingBottom: '66%'
        }}>
          <div style={{
            position: 'absolute',
            overflow: 'hidden',
            width: '100%',
            borderRadius: title ? 5 : 0,
            height: title ? (window.innerWidth < 812 ? 'calc(100% - 70px)' : 'calc(100% - 90px)') : '100%',
          }}>
            <img
              data-src='/images/fif_m11.png'
              src={api + preview}
              alt=''
              style={{
                position: 'relative',
                width: '100%',
                height: '100%'
              }}></img>
          </div>
        </div> : <video
          src={motion_video ? motion_video : (api + src)}
          playsinline
          preload='auto'
          loop
          muted autoPlay
          style={{ height: '100%' }}></video>}
        {title ? <div className="image_title">{title}</div> : null}
        {time ? <div className="image_time">
          <span>{time.substring(0, 10)}</span>
          <span>{address}</span>
        </div> : null}
        <div style={{
          height: title ? '10px' : '0',
        }}></div>
      </div>
      {show ? <div className="image_modal" style={{
        overflow: 'hidden',
        ...imgStyle
      }}>
        <div className="modal_back"
          onClick={() => setShow(false)}></div>
        <div
          className="modal_swiper_box"
          style={{
            opacity: visible ? 1 : 0,
            transition: '.5s',
            lineHeight: 0
          }}>
          <div className="modal_back"
            onClick={() => setShow(false)}></div>
          <Swiper
            className="modal_swiper"
            style={swiperStyle ? swiperStyle : {
              borderRadius: '10px',
              border: '4px solid #fff',
              maxHeight: 700
            }}
            loop={src.length > 1}
            spaceBetween={0}
            navigation
          >
            {src.length > 1 ? <SlideButton title='left' /> : null}
            {src.length > 1 ? <SlideButton title='right' /> : null}
            <div className="modal_swiper_slide">
              {src.map((item, index) => <SwiperSlide
                key={index}>
                <div className="image_box" onClick={() => {
                  window.innerWidth < 812 && setShow(false)
                }}>
                  {imgs.indexOf(item.split('.')[item.split('.').length-1].toLowerCase()) >= 0 ? <img alt=""
                    src={item.substring(0, 6) === '/media' ? (api + '/' + item) : item}></img> :
                    <video controls
                      style={{ objectFit: 'fill', width: '100%', height: '100%', mixBlendMode: 'cover' }}
                      src={item.substring(0, 6) === '/media' ? (api + '/' + item) : item}></video>}
                </div>
              </SwiperSlide>)}
            </div>
          </Swiper>
          <span dangerouslySetInnerHTML={{ __html: describe }}></span>
        </div>
      </div> : null}
    </div>
  );
}

export default Image;

function SlideButton(props) {
  const swiper = useSwiper();

  return (
    <div className={`swiper_control swiper_control_${props.title}`}
      onClick={() => {
        if (props.title === 'left') {
          swiper.slidePrev()
        } else {
          swiper.slideNext()
        }
      }}
      {...props}>
      <img alt="" src={`/images/left.png`}></img>
    </div>
  );
}
