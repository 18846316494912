

/**
 * 鼠标笑脸
 */
import React, { useState, useEffect, createContext } from 'react'
export const FaceContext = createContext({
  faceChange: () => null,
  faceShow: false
})
export default function FaceProvider({ children }) {
  const [faceShow, setShow] = useState(false)
  const faceChange = (e) => {
    // setShow(e)
  }
  const contextValue = {
    faceChange,
    faceShow
  }

  return (
    <FaceContext.Provider value={contextValue}>
      {children}
    </FaceContext.Provider>
  )
}
