

function ThirdPage() {
  return (
    <div className="third_page">

    </div>
  );
}

export default ThirdPage;
