import { useContext, useEffect, useState } from "react";
import api from "../../utils/api";
import Snap from 'snapsvg-cjs';
import { SkrollrContext } from "../../context/skrollrProvider";
import { LanguageContext } from "../../context/language";
import locals from "../../utils/locals";

function FourthPage() {
  const { s, skew } = useContext(SkrollrContext)
  const { local } = useContext(LanguageContext)
  // const [logos, setLogos] = useState([])
  // useEffect(() => {
  //   getLogos()
  // }, [])
  // const getLogos = () => {
  //   fetch(api + '/logos/logo/')
  //     .then((e) => e.json())
  //     .then((res) => {
  //       setLogos(res)
  //     })
  // }
  const renderOneItem = () => {
    return <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: window.innerWidth < 812 ? '50vh' : 'calc(100% - 6.7vh)',
      paddingBottom: window.innerWidth < 812 ? '2vh' : '0',
      paddingTop: window.innerWidth < 812 ? '2vh' : '0',
    }}>
      <div
        className={`skew_text ${local === 'en' ? 'dincond' : ''}`}
        style={{
          fontSize: window.innerWidth < 812 ?
            (local === 'en' ? '19.3vw' : '20.5vw') :
            (local === 'en' ? '7.9vw' : '8vw'),
          fontWeight: 'bolder',
          transform: `skew(${skew / 2}deg)`,
          textAlign: 'right',
          lineHeight: local === 'en' && window.innerWidth >= 812 ? '8vw' : 'auto',
          width: window.innerWidth < 812 ? 'auto' : '32vw',
          marginBottom: '1vw'
        }}
      >
        {locals[local].page3.title}
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: window.innerWidth < 812 ? '80vw' : '32vw',
      }}>
        <div style={{
          width: window.innerWidth < 812 ? '80%' : '100%',
          height: '13vw',
        }}>
          <div style={{
            width: window.innerWidth < 812 ? 'auto' : '10vw',
            fontSize: window.innerWidth < 812 ? '4.1vw' : '2vw',
            // marginBottom: window.innerWidth < 812 ? '80px' : '1vw',
            borderRight: window.innerWidth < 812 ? '1px solid black' : '2px solid black',
            height: window.innerWidth < 812 ? '3vw' : '1vw',
            lineHeight: window.innerWidth < 812 ? '3vw' : '1vw',
            transform: `skew(${skew / 2}deg)`,
            marginTop: window.innerWidth < 812 ? '.7vw' : '.3vw',
            marginBottom: window.innerWidth < 812 ? '10vw' : '4vw',
          }}
            className="skew_text">About us</div>
          <div className="flower">
            <img style={{
              width: window.innerWidth < 812 ? '90px' : '8.5vw'
            }} src="/images/flower.gif"></img>
          </div>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: window.innerWidth < 812 ? '5vw' : '1.3vw',
          lineHeight: window.innerWidth < 812 ?
            (local === 'en' ? '14px' : '20px') :
            (local === 'en' ? '110%' : '160%'),
          fontSize: window.innerWidth < 812 ? (local === 'en' ? '13px' : '14px') : (local === 'en' ? '1vw' : '1.1vw')
        }}>
          <span
            className="skew_text" style={{
              transform: `skew(${skew / 2}deg)`,
            }}>{locals[local].page3.content1}</span>
          <span style={{
            marginTop: '1vw',
            transform: `skew(${skew / 2}deg)`
          }}
            className="skew_text">{locals[local].page3.content2}</span>
        </div>
      </div>
    </div >
  }
  const [runanimate, setRun] = useState(false)
  useEffect(() => {
    if (window.innerWidth < 812 ? s && s.getScrollTop() > window.innerHeight * 2 : s && s.getScrollTop() > 2700) {
      setRun(true)
    } else {
      setRun(false)
    }
  }, [s && s.getScrollTop()])
  useEffect(() => {
    if (!runanimate || window.innerWidth < 812) return
    var svg = Snap("#arrow_svg");
    var arrow1 = svg.paper.path({
      d: "m 11.67867,0 c -4.19063,2.67837 -11.67867,0.0871 -11.67867,0.0871 0,0 5.09367,8.00038 3.81697,12.87552",
      stroke: '#010102',
      strokeWidth: 2,
      fill: 'none'
    });
    var path1 = svg.paper.path({
      d: 'm 684.45954,208.00273 c 0,0 59.87,-119.260001 -19.69,-181.450001 -83.98,-65.65 -343.49,-11.45 -564.32,242.650001',
      stroke: '#010102',
      strokeWidth: 2,
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10
    });
    var length1 = Snap.path.getTotalLength(path1);
    path1.attr({
      'stroke-dashoffset': length1,
      'stroke-dasharray': length1  // 用Snap的API计算复杂的path长度
    });
    Snap.animate(length1, 0, function (val) {
      path1.attr({
        'stroke-dashoffset': val
      });
      var point = Snap.path.getPointAtLength(path1, length1 - val)
      var m = new Snap.Matrix();
      m.translate(point.x, point.y);
      // m.translate(100,100);
      m.rotate(point.alpha - 42);
      arrow1.transform(m);
    }, 1500, null, function () {
      console.log('animation end');
    });
    // -----------------------------------------------  //
    var arrow2 = svg.paper.path({
      d: "m 11.67867,0 c -4.19063,2.67837 -11.67867,0.0871 -11.67867,0.0871 0,0 5.09367,8.00038 3.81697,12.87552",
      stroke: '#010102',
      strokeWidth: 2,
      fill: 'none'
    });
    var path2 = svg.paper.path({
      d: 'm 12.78954,419.97273 c 0,0 -35.23,88.19 16.25,142.73 83.36,88.31 349.81,19.87 560.55,-210.58',
      stroke: '#010102',
      strokeWidth: 2,
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10
    });
    var length2 = Snap.path.getTotalLength(path2);
    path2.attr({
      'stroke-dashoffset': length2,
      'stroke-dasharray': length2  // 用Snap的API计算复杂的path长度
    });
    Snap.animate(length2, 0, function (val) {
      path2.attr({
        'stroke-dashoffset': val
      });
      var point = Snap.path.getPointAtLength(path2, length2 - val)
      var m = new Snap.Matrix();
      m.translate(point.x, point.y);
      m.rotate(point.alpha - 40);
      arrow2.transform(m);
    }, 1500, null, function () {
      console.log('animation end');
    });
  }, [runanimate])
  const renderTwoItem = () => {
    return <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: window.innerWidth < 812 ? '100%' : 'auto',
      height: window.innerWidth < 812 ? '70vw' : 'calc(100% - 6.7vh)',
    }}>
      <div style={{
        position: 'relative',
        width: window.innerWidth < 812 ? '90%' : '72%',
        height: window.innerWidth < 812 ? '70vw' : '72vh',
        // marginLeft: window.innerWidth < 812 ? 'auto' : '-10%'
      }}>
        {runanimate && <svg className="fourth_two_arrow"
          viewBox="0,0,707.06494,600.90039"
          id="arrow_svg"></svg>}
        {window.innerWidth < 812 ? <img className="fourth_two_arrow" src="/images/arrow.svg"></img> : null}
        <div className="fourth_two_item" style={{
          left: 0,
          top: '50%',
          transform: local === 'en' ? 'translateY(-172.5%)' : 'translateY(-220%)',
          height: local === 'en' ? (window.innerWidth < 812 ? '15.36vw' : '6.4vw') : (window.innerWidth < 812 ? '12vw' : '5vw')
        }}>
          <div className="item_num">
            <img src={`/images/${local === 'en' ? 'e' : ''}01.png`}></img>
          </div>
        </div>
        <div className="fourth_two_item" style={{
          left: 0,
          top: '50%',
          left: '50%',
          transform: local === 'en' ? 'translate(-49.5%,-101%)' : 'translate(-50%,-143%)',
          height: local === 'en' ? (window.innerWidth < 812 ? '17.04vw' : '7.1vw') : (window.innerWidth < 812 ? '12vw' : '5vw')
        }}>
          <div className="item_num">
            <img src={`/images/${local === 'en' ? 'e' : ''}02.png`}></img>
          </div>
        </div>
        <div className="fourth_two_item" style={{
          right: 0,
          top: '50%',
          transform: local === 'en' ? 'translateY(-58%)' : 'translateY(-70%)',
          height: local === 'en' ? (window.innerWidth < 812 ? '14.64vw' : '6.1vw') : (window.innerWidth < 812 ? '12vw' : '5vw')
        }}>
          <div className="item_num">
            <img src={`/images/${local === 'en' ? 'e' : ''}03.png`}></img>
          </div>
        </div>
        <div className="fourth_two_item" style={{
          left: 0,
          top: '50%',
          transform: local === 'en' ? 'translateY(4%)' : 'translateY(5%)',
          height: local === 'en' ? (window.innerWidth < 812 ? '15.12vw' : '6.3vw') : (window.innerWidth < 812 ? '12vw' : '5vw')
        }}>
          <div className="item_num">
            <img src={`/images/${local === 'en' ? 'e' : ''}04.png`}></img>
          </div>
        </div>
        <div className="fourth_two_item" style={{
          left: 0,
          top: '50%',
          left: '50%',
          transform: local === 'en' ? 'translate(-50%,48%)' : 'translate(-50%,65%)',
          height: local === 'en' ? (window.innerWidth < 812 ? '16.32vw' : '6.8vw') : (window.innerWidth < 812 ? '12vw' : '5vw')
        }}>
          <div className="item_num">
            <img src={`/images/${local === 'en' ? 'e' : ''}05.png`}></img>
          </div>
        </div>
        <div className="fourth_two_item" style={{
          right: 0,
          top: '50%',
          transform: local === 'en' ? 'translateY(94%)' : 'translateY(130%)',
          height: local === 'en' ? (window.innerWidth < 812 ? '16.56vw' : '6.9vw') : (window.innerWidth < 812 ? '12vw' : '5vw')
        }}>
          <div className="item_num">
            <img src={`/images/${local === 'en' ? 'e' : ''}06.png`}></img>
          </div>
        </div>
      </div>
    </div>
  }
  const renderThreeItem = () => {
    return <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: window.innerWidth < 812 ? 'auto' : 'calc(100% - 6.7vh)',
    }}>
      <img style={{
        width: window.innerWidth < 812 ? '90%' : '72%',
      }} src={`/images/third_l${local === 'en' ? '2' : ''}.png`}></img>
      {/* <div style={{
        width: window.innerWidth < 812 ? '90%' : '72%',
        height: '26vw',
        marginTop: '3vw',
        marginBottom: '1vw'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}>
          {logos.map((item, index) => <div
            style={{ width: '16%', height: 'auto', margin: '1vw 0' }}
            key={index}>
            <img
              style={{ width: '100%' }}
              src={item.log_img} />
          </div>)}
        </div>
      </div> */}
      <img style={{
        width: window.innerWidth < 812 ? '90%' : '72%',
        marginTop: '3vw',
        marginBottom: '3vw'
      }} src="/images/third_p.png"></img>
    </div>
  }
  return (
    <div className="fourth_page" id='fourth-page'>
      {window.innerWidth >= 812 ? <div className="fourth_head"></div> : null}
      <div className="fourth_page_box">
        <div className="fourth_one fourth_item">
          {renderOneItem()}
        </div>
        <div className="fourth_two fourth_item">
          {renderTwoItem()}
        </div>
        <div className="fourth_three fourth_item">
          {renderThreeItem()}
        </div>
      </div>
    </div>
  );
}

export default FourthPage;
