import { useEffect } from "react";
import "../styles/search.css";
function Search(props) {
  const { style = {}, value, onSearch, onChange, otherProps } = props
  return (
    <div
      className={`search`}
      style={{
        ...style
      }}
      {...otherProps}>
      <input
        // placeholder="请输入搜索内容"
        onChange={e => onChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch && onSearch()
          }
        }}></input>
      <div onClick={() => onSearch && onSearch()} className="icon"><img src="/images/search.png" /></div>
    </div>
  );
}

export default Search;
