import { useContext } from "react";
import { LanguageContext } from "../context/language";
import "../styles/button.css";
function Button(props) {
  const { active, icon = false, children, style = {}, ...otherProps } = props
  const { local } = useContext(LanguageContext)
  return (
    <div
      className={`button button-hover ${active ? 'button-active' : ''}`}
      style={{
        minWidth: icon ? '6vh' : '20vh',
        ...style
      }}
      {...otherProps}>
      <div className={`${!icon ? 'button-child' : 'button-child-icon'}`}
        style={{
          textIndent: local === 'en' ? '0px' : '1.2vh',
          letterSpacing: local === 'en' ? '0px' : '1.2vh',
          animation:0
        }}>
        {children}
      </div>
    </div>
  );
}

export default Button;
