

/**
 * 动画
 */
import React, { useState, useEffect, createContext } from 'react'
import skrollr from "skrollr";
if (typeof window !== 'undefined') {
  window.skrollr = skrollr
  require("skrollr-menu")
}
export const SkrollrContext = createContext({

})
const pages = ['#first-page', '#second-page', '#fourth-page', '#fifth-page']
let s
export default function SkrollrProvider({ children }) {
  const [hash, setHash] = useState(0)
  const [curTop, setCurTop] = useState(0)
  const [scrollLength, setScrollLength] = useState(0)
  const [skew, setSkew] = useState(0)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (window.innerWidth < 812) return
    const limit = window.innerWidth >= 812 ?
      [[0, 1000], [1000, 3000], [3000, 4500], [4500, 5500]] :
      [[0, window.innerHeight], [window.innerHeight, window.innerHeight * 2 - 64], [window.innerHeight * 2 - 64, window.innerHeight * 3.5], [window.innerHeight * 3.5, window.innerHeight * 5]]
    s = window.skrollr.init({
      render: function (data) {
        let sk = data.lastTop - data.curTop
        if (window.innerWidth >= 812) {
          setSkew((sk >= -90 && sk <= 90) ? (data.curTop - data.lastTop) : 45)
          setTimeout(() => {
            setSkew(0)
          }, 500);
        }
        setScrollLength((data.curTop / 20).toFixed(0) % 20)
        limit.map((item, index) => {
          if (data.curTop >= item[0] && data.curTop < item[1]) {
            setCurTop(index)
          }
        })
      }
    })
    // if (window.innerWidth < 812) return
    window.skrollr.menu.init(s, {
      animate: true,
      easing: 'sqrt',
      duration: function (currentTop, targetTop) {
        return 500;
      },
      complexLinks: false,
      change: function (newHash) {
        setHash(newHash)
      },
      updateUrl: false
    });
    setLoading(false)
  }, [])
  useEffect(() => {
    setHash(pages[curTop])
  }, [curTop])
  const contextValue = {
    skrollr,
    hash,
    loading,
    s,
    scrollLength,
    skew
  }

  return (
    window.innerWidth < 812 ? <>
      {children}
    </> :
      <SkrollrContext.Provider value={contextValue}>
        {children}
      </SkrollrContext.Provider>
  )
}
