import { useState, useRef, useEffect, useContext } from "react";
import '../styles/home.css'
import Header from "../components/Header";
import FirstPage from "./home/firstPage";
import SecondPage from "./home/secondPage";
import ThirdPage from "./home/thirdPage";
import FourthPage from "./home/fourthPage";
import FifthPage from "./home/fifthPage";
import Contact from "./../components/Contact";
import Cartoon from "../components/Cartoon";
import PopupsProvider from "../context/popups";
import FifthModal from "../components/FifthModal";
import FaceProvider from "../context/face";
import FaceModal from "../components/FaceModal";
import { Cursor } from './../components/Crosshair/cursor';
import './../components/Crosshair/cursor';

document.documentElement.className = 'js';
function HomeScreen() {
  const faceRef = useRef()
  const cursorRef = useRef()
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (!cursorRef.current) return
    const cursor = new Cursor(cursorRef.current)
    document.querySelectorAll('.char').forEach(link => {
      link.addEventListener('mouseenter', () => cursor.enter());
      link.addEventListener('mouseleave', () => cursor.leave());
    });
  }, [])
  useEffect(() => {
    if (window.innerWidth < 812) return
    document.onmousewheel = onMousewheel
  }, [])
  const onMousewheel = (e) => {
    if (e.deltaX) {
      const val = window.skrollr.get().getScrollTop() + e.deltaX
      window.skrollr.get().setScrollTop(val)
    }
  }
  const FullPage = ({ style, children }) => {
    return <div
      style={{
        minWidth: '100%',
        height: '100vh',
        position: 'realtive',
        zIndex: 10,
        ...style
      }}>{children}</div>
  }
  const Container = ({ children }) => {
    if (window.innerWidth < 812) return <div>{children}</div>
    else return <div className="home"
      id='home'
      data-0='transform:translateX(0%)'
      data-4500='transform:translateX(-450%)'
    >{children}</div>
  }

  const contactProps = window.innerWidth >= 812 ? {
    'data-0': 'left:100%',
    // 'data-500': 'left:100%',
    'data-1000': 'left:0'
  } : {
    'data-0': 'bottom:-28.5vw;',
    'data-900': 'bottom:-28.5vw',
    'data-950': 'bottom:0vw',
  }
  const cartoonProps = window.innerWidth >= 812 ? {
    'data-0': 'left:100%',
    'data-900': 'left:100%',
    'data-1000': 'left:90%'
  } : {
    'data-0': 'bottom:-14vw;',
    'data-1500': 'bottom:-14vw',
    'data-1550': 'bottom:2vw',
  }
  return (
    <FaceProvider>
      <PopupsProvider>
        <Header isHome={!show} changeShow={e => setShow(e)} />
        <Container>
          <div className="first_back_img" data-500="opacity:1" data-1000="opacity:0">
            <img src="/images/first_back2.png"></img>
          </div>
          <FullPage><FirstPage /></FullPage>
          <FullPage><SecondPage faceRef={faceRef} changeShow={e => {
            setShow(e)
          }} /></FullPage>
          {window.innerWidth >= 812 ? <FullPage style={{
            zIndex: 9
          }}><ThirdPage /></FullPage> : null}
          <FullPage style={{
            height: window.innerWidth < 812 ? '150vh' : '100vh'
          }}><FourthPage /></FullPage>
          <FullPage style={{
            height: window.innerWidth < 812 ? 'auto' : '100vh'
          }}><FifthPage /></FullPage>
        </Container>
        {window.innerWidth < 812 ? null : <Cartoon {...cartoonProps}></Cartoon>}
        <Contact {...contactProps}></Contact>
        <FifthModal />
        {window.innerWidth < 812 ? null : <FaceModal faceRef={faceRef} />}
        {window.innerWidth < 812 ? null : <div className="cursor"
          ref={cursorRef}
          data-500="opacity:1"
          data-1000="opacity:0">
          <svg className="cursor__line cursor__line--horizontal" viewBox="0 0 200 20" preserveAspectRatio="none">
            <defs>
              <filter id="filter-noise-x" x="-50%" y="-50%" width="200%" height="200%"
                filterUnits="objectBoundingBox">
                <feTurbulence type="fractalNoise" baseFrequency="0" numOctaves="1" result="warp" />
                <feOffset dx="-30" result="warpOffset" />
                <feDisplacementMap xChannelSelector="R" yChannelSelector="G" scale="30" in="SourceGraphic" in2="warpOffset" />
              </filter>
            </defs>
            <line className="cursor__line-element" strokeDasharray="15,10" x1="0" y1="10" x2="200" y2="10" shapeRendering="crispEdges" vectorEffect="non-scaling-stroke" />
          </svg>
          <svg className="cursor__line cursor__line--vertical" viewBox="0 0 20 200" preserveAspectRatio="none">
            <defs>
              <filter id="filter-noise-y" x="-50%" y="-50%" width="200%" height="200%"
                filterUnits="objectBoundingBox">
                <feTurbulence type="fractalNoise" baseFrequency="0" numOctaves="1" result="warp" />
                <feOffset dy="-30" result="warpOffset" />
                <feDisplacementMap xChannelSelector="R" yChannelSelector="G" scale="30" in="SourceGraphic" in2="warpOffset" />
              </filter>
            </defs>
            <line className="cursor__line-element" strokeDasharray="15,10" x1="10" y1="0" x2="10" y2="200" shapeRendering="crispEdges" vectorEffect="non-scaling-stroke" />
          </svg>
        </div>}
      </PopupsProvider>
    </FaceProvider>
  );
}

export default HomeScreen;
