
const locals = {
  en: {
    header: {
      title1: 'work',
      title2: 'about',
      title3: 'contact',
      en: 'en',
      zh: 'zh'
    },
    page3: {
      title: 'VERY MEDIA',
      content1: 'Very Media, member unit of China Advertising Association, focuses on brand marketing in automobile and other fields, and provides one-stop integrated marketing services for many brands including top 500 enterprises in the world.',
      content2: 'Since its establishment in 2009, Very Media has continued to explore marketing strategies and measures to keep pace with the times, innovated to drive development, oriented with customer satisfaction and grew with partners together.',
    },
    join:'Join Us'
  },
  zh: {
    header: {
      title1: '整活',
      title2: '有料',
      title3: '入伙',
      en: '英',
      zh: '中'
    },
    page3: {
      title: '纬锐传媒',
      content1: '纬锐传媒，中国广告协会理事单位，专注于汽车及其他领域品牌营销，为包含世界500强在内众多品牌提供一站式整合营销服务。',
      content2: '自2009年成立以来，纬锐传媒持续探索与时俱进的营销策略及手段，创新驱动发展，以客户满意度为导向，与合作伙伴共同成长。',
    },
    join:'加入我们'
  }
}


export default locals